import tokenStore from '@eaze/eaze-api-client/es/token-store'

import { identify } from 'analytics'
import { identifyUser } from 'helpers/error-handler'
import { fetchCurrentAddress } from 'redux/action-wrappers/location'
import { setCookie } from 'redux/cookies/actions'

import t from './actionTypes'

export function removeUser() {
  return {
    type: t.REMOVE_USER
  }
}

function setUserBasic(userId: number, email?: string, firstName?: string, lastName?: string, zipcode?: string, mobilePhone?: string, expirationDate?: string) {
  return {
    type: t.SET_USER_BASIC,
    user: {
      userId,
      email,
      firstName,
      lastName,
      mobilePhone,
      zipcode,
      expirationDate
    }
  }
}

function setUserToken(token) {
  return {
    type: t.SET_USER_TOKEN,
    token
  }
}

export function setUserBasicAndCookie(userId: number, email?: string, firstName?: string, lastName?: string, zipcode?: string, mobilePhone?: string, expirationDate?: string) {
  identifyUser(userId) // Sentry
  identify(userId) // segment (analytics.js)

  return (dispatch)=> {
    dispatch(setCookie({ userId: userId.toString(), loggedin: true }))
    dispatch(setUserBasic(userId, email, firstName, lastName, zipcode, mobilePhone, expirationDate))
  }
}

export function setUserTokenAndCookie(token) {
  tokenStore.set(token)

  return (dispatch) => {
    dispatch(setCookie({ token }))
    dispatch(setUserToken(token))
    return dispatch(fetchCurrentAddress())
  }
}
