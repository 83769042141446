import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { getUserId } from '@/redux/auth/selectors'
import { fetchUserProfile } from '@/redux/profile/actions'

export default function useUserProfile() {
  const dispatch = useDispatch()
  const userId = useSelector(getUserId)

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserProfile(userId))
    }
  }, [dispatch, userId])
}
